<template>
<div class="main home">
    <div class="home-box">
        <h1>设备管理</h1>
        <div class="home-content">
            <!-- 查询条件 -->
            <search-item :searchInfo.sync="info" @searchList="searchList"></search-item>
            <div class="total">
                总表数:{{totalnum}} ; 在线水表:{{ online }} ; 离线水表:{{ offline }} ; 上报率:{{ cbl }}
            </div>
            <!-- 表格 -->
            <div class="table_container">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <el-table-column prop="eui" width="110" label="设备编号" align='center'>
                    </el-table-column>
                    <el-table-column prop="devNo" width="110" label="自定义编号" align='center'>
                    </el-table-column>
                    <el-table-column prop="imei" width="130" label="IMEI" align='center'>
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="getHistoryRecord(scope.row)">{{scope.row.imei}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="imsi" label="IMSI" width="130" align='center'>
                    </el-table-column>
                    <el-table-column prop="deviceModeName" width="60" label="运营商" align='center'>
                    </el-table-column>
                    <el-table-column prop="status" width="80" label="在线状态" align='center'>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == '0'" type="success">{{scope.row.statusName}}</el-tag>
                            <el-tag v-else type="danger">{{scope.row.statusName}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="enterpriseName" width="100" label="所属水司" align='center'>
                    </el-table-column>
                    <el-table-column prop="registerTime" width="100" label="注册时间" sortable align='center'>
                    </el-table-column>
                    <el-table-column prop="tapType" width="70" label="是否带阀" align='center'>
                        <template slot-scope="scope">
                            {{scope.row.tapType == 0 ? '无阀' : '有阀'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="tapStatus" width="70" label="阀门状态" align='center'>
                        <template slot-scope='scope'>
                            <span v-if="scope.row.tapStatus == 0">关阀</span>
                            <span v-if="scope.row.tapStatus == 1">开阀</span>
                            <span v-if="scope.row.tapStatus == 2">未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="readValue" width="70" label="最新读数" align='center'>
                    </el-table-column>
                    <el-table-column prop="readTime" width="90" label="上报时间" align='center'>
                    </el-table-column>
                    <el-table-column prop="electricity" width="60" label="电量" align='center'>
                    </el-table-column>
                    <el-table-column prop="rssi" width="80" label="信号强度" align='center'>
                    </el-table-column>
                    <el-table-column prop="batchNo" width="120" label="所属批次" align='center'>
                    </el-table-column>
                    <el-table-column prop="synchState" width="80" label="同步状态" align='center'>
                        <template slot-scope='scope'>
                            {{scope.row.synchState === 1 ? '已同步' : '未同步'}}
                        </template>
                    </el-table-column>
                    <el-table-column width="160px" fixed="right" label="操作" align='center'>
                        <template slot-scope='scope'>
                            <el-button type="text" size="mini" @click='sendSetValueCommand(scope.row)'>设置底数</el-button>
                            <el-button type="text" size="mini" @click='sendCommand(scope.row)'>发送指令</el-button>
                            <el-button type="text" size="mini" @click='modifyEnpr(scope.row)'>修改水司</el-button>
                            <el-button type="text" size="mini" @click='syncTo(scope.row)'>同步至抄表平台</el-button>
                            <el-button type="text" size="mini" @click='del(scope.row)'>删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
        </div>
    </div>
    <el-dialog :modal-append-to-body='false' title="修改水司" :visible.sync="modifyEnprVisible" width="25%">
        <el-form ref="form" label-width="120px">
            <el-form-item label="当前水司">
                <span style="color:#4295D5;">{{nowEnpr}}</span>
            </el-form-item>
            <el-form-item label="新水司">
                <el-select v-model="newEnpr" filterable clearable placeholder="请选择新的水司">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="enprLoading" @click="updateEnprSumit">提交</el-button>
                <el-button @click="closeEnprDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="设置读数" :visible.sync="setValueVisible" width="25%">
        <el-form ref="form" label-width="120px">
            <el-form-item label="水表底数">
                <el-input v-model="readValue" type="number" placeholder="请输入圈数(按照整数位输入)"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="setValueLoading" @click="onSubmitSetValue">提交</el-button>
                <el-button @click="closeSetValueDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="发送指令" :visible.sync="commandVisible" width="25%">
        <el-form ref="form" label-width="120px">
            <el-form-item label="指令选择">
                <el-radio v-model="command" label="0">开阀</el-radio>
                <el-radio v-model="command" label="1">关阀</el-radio>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="commandLoading" @click="onSubmit">提交</el-button>
                <el-button @click="closeCommandDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="历史上报记录" :visible.sync="reportVisible" width="60%">
        <div class="table_container">
            <el-table size="mini" border :data="historyDatas" stripe v-loading="tableLoading1" style="width: 100%" align='center'>
                <el-table-column label="设备编号" align='center'>
                    <template slot-scope="scope">
                        {{nowDeviceInfo.eui}}
                    </template>
                </el-table-column>
                <el-table-column label="IMEI" align='center'>
                    <template slot-scope="scope">
                        {{nowDeviceInfo.imei}}
                    </template>
                </el-table-column>
                <el-table-column prop="readValue" label="读数" align='center'>
                </el-table-column>
                <el-table-column prop="uplinkTime" label="上报时间" align='center'>
                </el-table-column>
                <el-table-column prop="signal" label="RSSI" align='center'>
                </el-table-column>
                <el-table-column prop="electricity" label="电量" align='center'>
                </el-table-column>
                <el-table-column prop="tapStatus" label="阀门状态" align='center'>
                    <template slot-scope='scope'>
                        <span v-if="scope.row.tapStatus == 0">关阀</span>
                        <span v-if="scope.row.tapStatus == 1">开阀</span>
                        <span v-if="scope.row.tapStatus == 2">未知</span>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :paginationInfo="paginationInfo1" @handleCurrentChange="handleCurrentChange1" @handleSizeChange="handleSizeChange1"></pagination>
        </div>
    </el-dialog>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
export default {
    data() {
        return {
            // 表格loading
            tableLoading: false,
            tableLoading1: false,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 8, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            paginationInfo1: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 8, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            pidList: [],
            yxqVisible: false,
            registerId: "",
            info: {
                deviceNo: "",
                imei: "",
                deviceMode: "",
                status: "",
                enterpriseId: "",
                tapStatus: "",
                electricity: "",
                batchNo: "",
                type: null
            },
            commandVisible: false,
            command: '0',
            modifyEnprVisible: false,
            nowEnpr: '',
            nowDeviceId: '',
            newEnpr: '',
            arrEnpr: [],
            selectedRow: '',
            enprLoading: false,
            commandLoading: false,
            reportVisible: false,
            historyDatas: [],
            nowDeviceInfo: {
                imei: '',
                eui: ''
            },
            totalnum: null,
            online: null,
            offline: null,
            cbl: null,
            readValue: '',
            setValueVisible: false,
            setValueLoading: false
        }
    },
    mounted() {
        let that = this
        this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        this.getAllEnpr()
    },
    methods: {
        //  获取表格信息
        getDeviceList(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                pageNum: currpage,
                pageSize: pageSize,
                eui: this.info.deviceNo,
                imei: this.info.imei,
                deviceMode: this.info.deviceMode,
                status: this.info.status,
                enterpriseId: this.info.enterpriseId,
                tapStatus: this.info.tapStatus,
                electricity: this.info.electricity,
                batchNo: this.info.batchNo,
                type: parseInt(this.info.type)
            }
            this.tableData = []
            this.$api.getDecieList(params).then(res => {
                // console.log('000', res.data.pageList)
                this.tableLoading = false;
                if (res && res.code == 0) {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData = res.data.list
                    if (res.data.total > res.data.onlineTotal) {
                        this.totalnum = res.data.total
                        this.online = res.data.onlineTotal
                        this.offline = res.data.total - res.data.onlineTotal
                        this.cbl = (res.data.onlineTotal / res.data.total * 100).toFixed(2) + '%'
                    }
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getDeviceList(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 上下分页
        handleCurrentChange1(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo1.pageIndex = val
            this.getHistoryData(that.paginationInfo1.pageIndex, that.paginationInfo1.pageSize, that.nowDeviceInfo)
        },
        // 每页显示多少条
        handleSizeChange1(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo1.pageSize = val
            this.getHistoryData(that.paginationInfo1.pageIndex, that.paginationInfo1.pageSize, that.nowDeviceInfo)
        },
        getHistoryData(currpage, pageSize, info) {
            this.reportVisible = true
            this.tableLoading1 = true;
            let params = {
                pageNum: currpage,
                pageSize: pageSize,
                deviceId: info.deviceId
            }
            this.historyDatas = []
            this.$api.deviceHistoryList(params).then(res => {
                // console.log('000', res.data.pageList)
                this.tableLoading1 = false;
                if (res && res.code == 0) {
                    this.paginationInfo1.pageTotal = parseInt(res.data.total)
                    this.paginationInfo1.pageIndex = res.data.pageNum
                    this.historyDatas = res.data.list
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading1 = false;
            })
        },
        goEdit(e) {
            console.log(e)
            this.$router.push({
                path: '/',
                query: {
                    id: e.courseId
                }
            })
        },
        submitDialog(e) {
            this.registerId = e.registerId
            this.centerDialogVisible = true
        },
        onSubmitSetValue(){
            if (this.readValue == '') {
                this.$message.warning('请输入底数圈数')
                return
            }
            if(parseInt(this.readValue, 10) != this.readValue){
                this.$message.warning('请输入整数圈数')
                return
            }
            let params = {
                    imei: this.selectedRow.imei,
                    readValue: this.readValue
                }
                this.setValueLoading = true
                this.$api.setValue(params).then(res => {
                        if (res && res.code === 0) {
                            this.setValueVisible = false
                            this.setValueLoading = false
                            this.$message({
                                message: '设置底数指令发送成功,等待下发到设备',
                                type: 'success'
                            })
                        } else {
                            this.setValueLoading = false
                            this.$message({
                                message: '指令下发失败',
                                type: 'error'
                            })
                        }
                    })
        },
        onSubmit() {
            console.log(this.selectedRow)
            if (this.selectedRow.type === 3) {
                let params = {
                    imei: this.selectedRow.imei,
                    tapStatus: this.command
                }
                this.commandLoading = true
                this.$api.controlADKValve(params).then(res => {
                        if (res && res.code === 0) {
                            this.commandVisible = false
                            this.commandLoading = false
                            this.$message({
                                message: '阀控指令发送成功,等待下发到设备',
                                type: 'success'
                            })
                        } else {
                            this.commandLoading = false
                            this.$message({
                                message: '指令下发失败',
                                type: 'error'
                            })
                        }
                    })
            } else if (this.selectedRow.deviceMode === 2) {
                let params = {
                    imei: this.selectedRow.imei,
                    command: this.command
                }
                this.commandLoading = true
                this.$api.releaseOrder(params).then(res => {
                    if (res && res.code === 0) {
                        this.commandVisible = false
                        this.commandLoading = false
                        this.$message({
                            message: '阀控指令发送成功,等待下发到设备',
                            type: 'success'
                        })
                    } else {
                        this.commandLoading = false
                        this.$message({
                            message: '指令下发失败',
                            type: 'error'
                        })
                    }
                })
            } else {
                let params = {
                    deviceId: this.selectedRow.deviceId,
                    command: this.command
                }
                this.commandLoading = true
                this.$api.releaseOrder2(params).then(res => {
                    if (res && res.code === 0) {
                        this.commandVisible = false
                        this.commandLoading = false
                        this.$message({
                            message: '阀控指令发送成功,等待下发到设备',
                            type: 'success'
                        })
                    } else {
                        this.commandLoading = false
                        this.$message({
                            message: '指令下发失败',
                            type: 'error'
                        })
                    }
                })
            }

        },
        closeSetValueDialog(){
            this.setValueVisible = false
        },
        closeCommandDialog() {
            this.commandVisible = false
        },
        closeEnprDialog() {
            this.modifyEnprVisible = false
            this.newEnpr = ''
        },
        //更新设备所在的水司
        updateEnprSumit() {
            if (this.newEnpr == '') {
                this.$message.warning('请选择新的水司')
                return
            }
            this.enprLoading = true
            this.$api.updateEnpr({
                id: this.nowDeviceId,
                enterpriseId: this.newEnpr
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('修改水司成功')
                    this.getDeviceList(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                    this.modifyEnprVisible = false
                    this.enprLoading = false
                    this.newEnpr = ''
                } else {
                    this.enprLoading = false
                    this.$message.error('修改水司失败')
                }
            })
        },
        //启用或停用设备
        syncTo(e) {
            console.log(e)
            this.$confirm(`确定要将IMEI为 ${e.imei} 的设备同步到抄表平台吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.synchronizationOne({
                    id: e.id
                }).then(res => {
                    if (res.code === 0) {
                        this.$message.success('同步完成')
                        this.getDeviceList(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                    } else {
                        this.$message.success(res.msg)
                    }
                })
            })
        },
        del(e) {
            console.log(e)
            let that = this
            this.$confirm('确定要删除该设备吗?删除后将无法恢复，需重新注册', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.delDevice({
                    id: e.id
                }).then(res => {
                    if (res.code === 0) {
                        this.getDeviceList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        //获取所有水司
        getAllEnpr() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
         //设置底数弹窗
         sendSetValueCommand(e) {
            this.setValueVisible = true
            this.selectedRow = e
        },
        //发送指令弹框
        sendCommand(e) {
            this.commandVisible = true
            this.selectedRow = e
        },
        //修改水司弹框
        modifyEnpr(e) {
            console.log(e)
            this.nowEnpr = e.enterpriseName
            this.nowDeviceId = e.id
            this.modifyEnprVisible = true
        },
        getHistoryRecord(e) {
            this.nowDeviceInfo = e
            this.getHistoryData(1, 10, e)
        }
    },
    components: {
        Pagination,
        SearchItem
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .total {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: red;
    font-size: 18px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .name-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.openstate {
    color: #8CC663;
}

.stopstate {
    color: #e92d0c;
}
</style>
