<template>
<div id="batchRegistDevice" class="main home">
    <div class="home-box">
        <h1>批量注册设备</h1>
        <div class="home-content">
            <span class="tip">请 <a download="download" href="https://nb-iot.zhdt-tech.com/%E6%89%B9%E9%87%8F%E6%B3%A8%E5%86%8C%E6%A8%A1%E6%9D%BF.xlsx">下载模板</a> 并在模板上填写需要导入注册的信息后进行上传并注册,每次注册最多上传一个Excel文件</span><br><br>
            <el-upload ref="upload" class="upload-demo" drag :action="fileUploads.actionUrl" accept=".xlsx,.xls" :headers="fileUploads.authToken" :data="fileUploads.extraData" :disabled="fileUploads.uploadStatus" :before-upload="beforeUploadDatas" :on-success="importDatas" :on-error="importError" :multiple="false"  :auto-upload="false" :file-list="filelist" :on-change="fileChange" :limit="1">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">单次最大注册量为500块设备</div>
            </el-upload>
            <br><br>
            <el-button type="primary" :loading="submitLoading" @click="onSubmit">{{submitLoading ? '注册中' : '注册'}}</el-button>
            <el-button @click="back">返回</el-button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                imei: '',
                imsi: '',
                enterpriseId: '',
                deviceDesc: '',
                eui: '',
                moduleFactory: 1,
                type: '',
                waterType: '1',
                caliber: ''

            },
            arrEnpr: [],
            dialogVisible: false,
            editState: false,
            operatorValue: '',
            operatorList: [{
                    value: '0',
                    label: '移动'
                },
                {
                    value: '1',
                    label: '电信'
                }
            ],
            deviceTypeValue: '',
            deviceTypeList: [],
            submitLoading: false,
            fileUploads: {
                actionUrl: process.env.VUE_APP_BASE_API + '/iot/device/batchRegistration',
                authToken: {
                    token: sessionStorage.getItem('token')
                },
                uploadStatus: false,
                extraData: {}
            },
            filelist: []
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.editState = true
            this.initCourseData(this.$route.query.id)
        } else {
            this.getAllEnprs()
        }
    },
    beforeDestroy() {

    },
    methods: {
        getAllEnprs() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        onSubmit() {
            console.log(this.filelist)
            if (this.filelist.length !== 1) {
                this.$message.warning('请检查需要注册的Excel模板文件的个数是否正常')
            } else {
                this.submitLoading = true
                this.$refs.upload.submit()
            }

        },
        back() {
            history.back()
        },
        beforeUploadDatas(file) {
            console.log(file)
            this.fileUploads.uploadStatus = true
        },
        importDatas(response, file, fileList) {
            let that = this
            this.submitLoading = false
            console.log('----上传文件----', response, file)
            this.fileUploads.uploadStatus = false
            if(response && response.code === 0){
                this.$message.success('批量注册设备完成')
                setTimeout(() => {
                    that.$router.push({
                        path: '/index'
                    })
                }, 2000);
            }else {
                this.$message.error(response.msg)
            }
        },
        importError(response, file, fileList) {
            this.$message.error('注册出现异常,刷新页面重新尝试')
            this.submitLoading = false
            this.filelist = []
            this.$refs.upload.clearFiles()
        },
        fileChange(file, fileList) {
            // console.log(file.raw)
            this.filelist = fileList
        }
    }
}
</script>

<style lang="scss">
#batchRegistDevice {
    .inputItem {
        width: 340px;
    }

    .el-input__inner {
        width: 340px;
    }

    .home-content {
        padding-top: 20px;
        padding-bottom: 20px;

        .tip {
            font-size: 12px;
            color: #324054;

            a {
                text-decoration: underline;
            }
        }
    }

    .el-form-item__content {

        align-items: center;
    }
}
</style>
