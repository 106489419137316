<template>
<div class="in-report-manage">
    <component :is="views" @goEdit="goEdit"></component>
</div>
</template>

<script>
import sbList from '@/views/SBGL/Device/components/sbList';
import sbEdit from '@/views/SBGL/Device/components/sbEdit';
import batchRegist from '@/views/SBGL/Device/components/batchRegist';
import batchUpdateDeviceNo from '@/views/SBGL/Device/components/batchUpdateDeviceNo';
import jzqEdit from '@/views/SBGL/Device/components/jzq/sbEdit';
export default {
    data() {
        return {
            views: sbList
        }
    },
    methods: {
        goEdit(row) {
            console.log(row)
            this.$router.push({
                path: '/caseManage/caseindex',
                query: {
                    json: JSON.stringify(row)
                }
            })
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.state) {
            if (this.$route.query.state === 'add') {
                this.views = sbEdit
            } else if (this.$route.query.state === 'batch') {
                this.views = batchRegist
            } else if (this.$route.query.state === 'batchUpdate') {
                this.views = batchUpdateDeviceNo
            }else if (this.$route.query.state === 'jzqadd') {
                this.views = jzqEdit
            }
             else {
                this.views = sbList
            }

        } else {
            this.views = sbList
        }
    }
}
</script>
